import './App.css';

function App() {
  return (
    <div className="app">
      <div className="app__brand">
        SLKHV
      </div>
      <div className="app__description">
        Creative developer & designer based in WEB
      </div>
    </div>
  );
}

export default App;
